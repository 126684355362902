import { useState, useMemo } from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined, EyeOutlined, LinkOutlined, RiseOutlined, RollbackOutlined } from '@ant-design/icons';
import useRdxStore from '../../hooks/useRdxStore';
import CacheManager from '../../services/api/cache/CacheManager';
import DomainModal from '../modals/DomainModal';
import PublishedWebsiteModal from '../modals/PublishedWebsiteModal';
import { ENVIRONMENTS } from '../../exports/Enums';

function Header(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const [showDomainModal, setShowDomainModal] = useState<boolean>(false);

    const [showPublishedWebsiteModal, setShowPublishedWebsiteModal] = useState<boolean>(false);

    const cacheStore = async (): Promise<void> => {
        setLoading(true);
        await CacheManager.post();
        setTimeout(() => {
            setLoading(false);
            setShowPublishedWebsiteModal(true);
        }, 2000);
    };

    const renderDomainModal = useMemo(
        () => <DomainModal open={showDomainModal} toggle={() => setShowDomainModal(false)} />,
        [showDomainModal],
    );

    const renderPublishedWebsiteModal = useMemo(
        () => (
            <PublishedWebsiteModal
                open={showPublishedWebsiteModal}
                toggle={() => setShowPublishedWebsiteModal(false)}
            />
        ),
        [showPublishedWebsiteModal],
    );

    function renderDomainAction(): JSX.Element | null {
        if (import.meta.env.VITE_NODE_ENV !== ENVIRONMENTS.PROD || !store?.onboarding_completed) {
            return null;
        }

        return (
            <div className="flex items-center">
                <Button
                    onClick={cacheStore}
                    loading={loading}
                    disabled={loading}
                    className="ml-small"
                    type="primary"
                    icon={<RiseOutlined />}
                >
                    Publish Website changes
                </Button>
                <Button onClick={() => setShowDomainModal(true)} className="ml-small" icon={<LinkOutlined />}>
                    Domain
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="w-full border-brand-inkGrey-grey_2 border-t-none border-r-none border-l-none border-solid bg-background-inkWhite-white_0 flex items-center justify-between p-small">
                <div className="flex items-center">
                    <h6 className="lg:flex hidden">{store?.name}</h6>
                    {!store?.onboarding_completed && (
                        <p className="text-brand-text-grey">
                            <ArrowRightOutlined className="mx-mini" /> Website Creation
                        </p>
                    )}
                </div>

                <div className="lg:flex hidden items-center">
                    <Button
                        href={`${import.meta.env.VITE_ADMIN_DASHBOARD_URL}/storefront/overview`}
                        className="mr-small"
                        icon={<RollbackOutlined />}
                    >
                        Admin Dashboard
                    </Button>
                    <Button href={`https://${store?.preview_domain}`} target="_blank" icon={<EyeOutlined />}>
                        Preview Website
                    </Button>
                    {renderDomainAction()}
                </div>
            </div>
            {renderDomainModal}
            {renderPublishedWebsiteModal}
        </>
    );
}

export default Header;
