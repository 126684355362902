/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Response } from '../../exports/Interfaces';
import AuthManager from './AuthManager';
import { COOKIES } from '../../exports/Constants';
import AppContextResource from '../resources/AppContextResource';
import CookiesResource from '../resources/CookiesResource';

class BackendApiClient {
    private client: AxiosInstance = axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
    });

    constructor() {
        this.client.interceptors.response.use(
            (response) => response,
            async (error) => this.handleErrors(error),
        );
    }

    public async request<Type>(configuration: AxiosRequestConfig, isFormData = false): Promise<Response<Type>> {
        if (this.getToken()) {
            return this.requestAuthorized<Type>(configuration, isFormData);
        }

        return this.requestAsync<Type>(configuration);
    }

    public async requestAsync<Type>(configuration: AxiosRequestConfig): Promise<Response<Type>> {
        try {
            const response: any = await this.client.request(configuration);

            return {
                success: true,
                response,
                status: response?.status,
            };
        } catch (error: any) {
            return {
                success: false,
                response: error,
                status: error?.response?.status,
            };
        }
    }

    public async requestAuthorized<Type>(
        configuration: AxiosRequestConfig,
        isFormData = false,
    ): Promise<Response<Type>> {
        const newConfig = this.createRequestConfig(configuration, isFormData);

        if (!newConfig?.headers?.Authorization.split('Bearer ')[1]) {
            return {
                success: false,
                response: null,
                status: 401,
            };
        }

        try {
            const response: any = await this.client.request(newConfig);
            return {
                success: true,
                response,
                status: response.status,
            };
        } catch (error: any) {
            return {
                success: false,
                response: error,
                status: error?.response?.status,
            };
        }
    }

    private createRequestConfig(configuration: AxiosRequestConfig, isFormData = false): AxiosRequestConfig {
        return {
            ...configuration,
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
                'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
                Accept: 'application/json',
                'on-behalf-of-store': this.getOnBehalfOfStore(),
                ...configuration?.headers,
            },
        };
    }

    private handleErrors = async (error: any): Promise<any> => {
        if (error?.response?.status === 403) {
            AppContextResource?.cookieContext.removeCookies([COOKIES.AUTH_TOKEN]);

            return Promise.reject(error);
        }

        if (error?.response?.status !== 401 || error.config.url === '/refresh-token') {
            return Promise.reject(error);
        }

        const authToken = this.getToken();

        if (!authToken || authToken === 'null' || authToken === 'undefined') {
            AppContextResource?.cookieContext.removeCookies([COOKIES.AUTH_TOKEN]);

            return Promise.reject(error);
        }

        return AuthManager.refreshToken().then((request) => {
            if (request.success) {
                return this.requestAuthorized({
                    ...error.config,
                    headers: {
                        ...error.config.headers,
                        Authorization: `Bearer ${request.response.data.data.access_token}`,
                    },
                });
            }

            return AppContextResource?.cookieContext.removeCookies([COOKIES.AUTH_TOKEN]);
        });
    };

    public getOnBehalfOfStore(): number | null {
        const id = CookiesResource.getCookie(COOKIES.ON_BEHALF_OF_STORE);
        return typeof id === 'string' ? Number(id) : null;
    }

    public getToken(): string {
        return CookiesResource.getCookie(COOKIES.AUTH_TOKEN);
    }
}

export default new BackendApiClient();
