import { useState } from 'react';
import { Button, Form, Input, Select, SelectProps, Switch } from 'antd';
import collect from 'collect.js';
import CustomForm from '../../forms/CustomForm';
import useRdxStore from '../../../hooks/useRdxStore';
import StoreManager from '../../../services/api/StoreManager';
import { StoreInterface } from '../../../exports/Interfaces';
import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import { CURRENCY_TYPES, DIETARY_ATTRIBUTES, VENUE_TYPES } from '../../../exports/Enums';

function GeneralInfoForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        await StoreManager.put(
            store?.id,
            collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<StoreInterface>,
        );
        setLoading(false);
    };

    const dietaryOptions: SelectProps['options'] = [
        {
            label: 'Vegetarian',
            value: DIETARY_ATTRIBUTES.VEGETARIAN,
        },
        {
            label: 'Vegan',
            value: DIETARY_ATTRIBUTES.VEGAN,
        },
        {
            label: 'Halal',
            value: DIETARY_ATTRIBUTES.HALAL,
        },
        {
            label: 'Gluten Free',
            value: DIETARY_ATTRIBUTES.GLUTEN_FREE,
        },
        {
            label: 'Dairy Free',
            value: DIETARY_ATTRIBUTES.DAIRY_FREE,
        },
        {
            label: 'Spicy',
            value: DIETARY_ATTRIBUTES.SPICY,
        },
    ];

    return (
        <RestaurantInfoLayout title="General Information">
            <CustomForm>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                        ...store,
                        currency: store?.currency ?? CURRENCY_TYPES.EURO,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Company Name">
                        <Input placeholder="Enter Company Name" />
                    </Form.Item>

                    <Form.Item name="phone_number" label="Phone number">
                        <Input placeholder="Enter Phone Number" />
                    </Form.Item>

                    <Form.Item name="email" label="Email">
                        <Input placeholder="Enter Email Address" />
                    </Form.Item>

                    <div className="grid lg:grid-cols-5 grid-cols-1 gap-2">
                        <Form.Item name="address" label="Address" className="lg:col-span-2">
                            <Input placeholder="Enter Address" />
                        </Form.Item>
                        <Form.Item name="zip_code" label="Zip Code" className="lg:col-span-1">
                            <Input placeholder="Enter Zip Code" />
                        </Form.Item>
                        <Form.Item name="city" label="City" className="lg:col-span-1">
                            <Input placeholder="Enter City" />
                        </Form.Item>
                        <Form.Item name="quarter" label="District" className="lg:col-span-1">
                            <Input placeholder="Enter district" />
                        </Form.Item>
                    </div>

                    <Form.Item name="currency" label="Currency">
                        <Select
                            placeholder="Select Layout"
                            options={[
                                { value: CURRENCY_TYPES.EURO, label: '€ Euro ' },
                                {
                                    value: CURRENCY_TYPES.POUND,
                                    label: '£ Pound',
                                },
                                { value: CURRENCY_TYPES.DOLLAR, label: '$ Dollar' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item name="venue_type" label="Venue">
                        <Select
                            placeholder="Select Venue"
                            options={[
                                { value: VENUE_TYPES.RESTAURANT, label: 'Restaurant' },
                                { value: VENUE_TYPES.CAFE, label: 'Cafe' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item name="delivery_areas" label="What areas do you serve in the city?">
                        <Select
                            mode="tags"
                            placeholder="Enter areas"
                            tokenSeparators={[',']}
                            className="w-full"
                            showArrow={false}
                            open={false}
                        />
                    </Form.Item>
                    <Form.Item name="best_dishes" label="What are your top 3 dishes">
                        <Select
                            mode="tags"
                            placeholder="Enter dishes (Chicken burger, Cheese burger, etc.)"
                            tokenSeparators={[',']}
                            className="w-full"
                            showArrow={false}
                            open={false}
                        />
                    </Form.Item>
                    <Form.Item name="dietary_attributes" label="Do you have options for people who want to eat:">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={dietaryOptions}
                        />
                    </Form.Item>
                    <Form.Item name="family_owned" label="Family owned?">
                        <Switch defaultChecked={!!store.family_owned} />
                    </Form.Item>
                    <Form.Item label="Can you share your origin story? Why did you start?" name="origin_story">
                        <Input.TextArea rows={10} placeholder="Enter origin story" />
                    </Form.Item>
                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </RestaurantInfoLayout>
    );
}

export default GeneralInfoForm;
