import { combineReducers, AnyAction, Store } from 'redux';
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import StoreSlice from './slices/StoreSlice';
import InterceptorMiddleware from './middleware/InterceptorMiddleware';
import RootReducerMiddleware from './middleware/RootReducerMiddleware';
import MerchantSlice from './slices/MerchantSlice';
import AppStatusSlice from './slices/AppStatusSlice';

let rehydrationComplete: any;

const rehydrationPromise = new Promise<boolean>((resolve) => {
    rehydrationComplete = resolve;
});

export function rehydration(): Promise<boolean> {
    return rehydrationPromise;
}

const reducerPersisted = persistReducer<any>(
    {
        key: 'root',
        storage,
    },
    combineReducers({
        appStatus: AppStatusSlice.reducer,
        store: StoreSlice.reducer,
        user: MerchantSlice.reducer,
    }),
);

const configure = (): Store =>
    configureStore({
        reducer: (state, action) => RootReducerMiddleware(state, action as AnyAction, reducerPersisted),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(InterceptorMiddleware as Middleware),
    });

export const store: Store = configure();

export const persistor: Persistor = persistStore(store, null, () => {
    rehydrationComplete();
});
