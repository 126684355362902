import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import AppLayout from '../../components/layouts/onboarding/AppLayout';
import { ONBOARDING_PAGES } from '../../exports/Enums';
import Seo from '../../pages/onboarding/seo';
import OrderMethods from '../../pages/onboarding/order-methods';
import Catering from '../../pages/onboarding/catering';
import Events from '../../pages/onboarding/events';
import Social from '../../pages/onboarding/social';
import GoogleReviews from '../../pages/onboarding/google-reviews';
import { OnboardingRouterInterface } from '../../exports/Interfaces';
import Menus from '../../pages/onboarding/menus';

export const onboardingRouter: OnboardingRouterInterface[] = [
    {
        element: <AppLayout />,
        children: [
            {
                title: 'SEO',
                path: `${ONBOARDING_PAGES.SEO}`,
                element: <Seo />,
            },
            {
                title: 'Order Methods',
                path: `${ONBOARDING_PAGES.ORDER_METHODS}`,
                element: <OrderMethods />,
            },
            {
                title: 'Social Links',
                path: `${ONBOARDING_PAGES.SOCIAL}`,
                element: <Social />,
            },
            {
                title: 'Reviews',
                path: `${ONBOARDING_PAGES.GOOGLE_REVIEWS}`,
                element: <GoogleReviews />,
            },
            {
                title: 'Events',
                path: `${ONBOARDING_PAGES.EVENTS}`,
                element: <Events />,
            },
            {
                title: 'Catering',
                path: `${ONBOARDING_PAGES.CATERING}`,
                element: <Catering />,
            },
            {
                title: 'Menus',
                path: `${ONBOARDING_PAGES.MENUS}`,
                element: <Menus />,
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to={ONBOARDING_PAGES.SEO} replace />,
    },
];

function OnboardingRouter(): JSX.Element {
    return <RouterProvider router={createBrowserRouter(onboardingRouter)} />;
}

export default OnboardingRouter;
