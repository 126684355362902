import { Button, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import SectionLayout from '../../../components/layouts/SectionLayout';
import PageManager from '../../../services/api/page/PageManager';
import { PageContentInterface } from '../../../exports/Interfaces';
import useModals from '../../../hooks/useModals';
import PageLayout from '../page-layout';
import useWindowSize from '../../../hooks/useWindowSize';
import useScreenType from '../../../hooks/useScreenType';
import { PAGE_IDENTIFIERS, PAGES, SUB_PAGES } from '../../../exports/Enums';
import useRdxStore from '../../../hooks/useRdxStore';

function HomePageLayout(): JSX.Element {
    const { page: pageId } = useParams();

    const { popupModal, sectionModal, featureModal, setFeatureModal } = useModals();

    const { width } = useWindowSize();

    const { isDesktop } = useScreenType();

    const { store } = useRdxStore();

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const [page, setPage] = useState<PageContentInterface | null>(null);

    const [mounted, setMounted] = useState<boolean>(false);

    const mountedRef = useRef(mounted);
    mountedRef.current = mounted;

    const requestPage = async (showLoading = true): Promise<void> => {
        if (showLoading) {
            setLoading(true);
        }
        const { response, success } = await PageManager.get(Number(pageId));
        setLoading(false);
        setMounted(true);

        if (success) {
            setPage({ ...response?.data?.data });
            return;
        }

        navigate(
            `${PAGES.LAYOUT}${SUB_PAGES.PAGES}/${
                collect(store?.pages).firstWhere('identifier', PAGE_IDENTIFIERS.HOME)?.id
            }`,
        );
    };

    useEffect(() => {
        requestPage();
    }, []);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        if (!popupModal?.open && !sectionModal?.open && !featureModal?.open) {
            requestPage(false);
        }
    }, [popupModal?.open, sectionModal?.open, featureModal?.open]);

    function getContent(): JSX.Element | null {
        if (loading) {
            return <Spin size="large" />;
        }
        if (page) {
            return <PageLayout page={page} />;
        }
        return null;
    }

    const getContentWidth = useMemo(
        (): number =>
            window.innerWidth -
            ((document.getElementById('sidebar')?.getBoundingClientRect().width || 0) +
                (document.getElementById('middle-content')?.getBoundingClientRect().width || 0)),
        [mounted, width],
    );

    if (!isDesktop) {
        return (
            <SectionLayout
                title="Layout"
                rightContent={
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => setFeatureModal({ open: true, pageId: Number(pageId) })}
                    />
                }
            >
                <div className="pb-[200px]">{getContent()}</div>
            </SectionLayout>
        );
    }

    return (
        <div className="fixed" style={{ width: getContentWidth }}>
            <SectionLayout
                title="Layout"
                hideClose
                rightContent={
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        className="bg-black"
                        onClick={() => setFeatureModal({ open: true, pageId: Number(pageId) })}
                    >
                        Features
                    </Button>
                }
            >
                <div className="pb-[200px]">{getContent()}</div>
            </SectionLayout>
        </div>
    );
}

export default HomePageLayout;
