import { DataWrapper, Response, StoreSetupInterface } from '../../../../exports/Interfaces';
import BackendApiClient from '../../BackendApiClient';

export default class StoreSetupManager {
    public static get = async (): Promise<Response<DataWrapper<StoreSetupInterface>>> =>
        BackendApiClient.request<DataWrapper<StoreSetupInterface>>({
            method: 'GET',
            url: '/setup',
        });
}
