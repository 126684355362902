import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { PAGE_IDENTIFIERS } from '../../../exports/Enums';
import StaticPageManager from '../../../services/api/page/StaticPageManager';

function StaticPage(): JSX.Element | null {
    const [loadingIdentifier, setLoadingIdentifier] = useState<PAGE_IDENTIFIERS | null>();

    const onSave = async (identifier: PAGE_IDENTIFIERS): Promise<void> => {
        setLoadingIdentifier(identifier);
        const { success, response, status } = await StaticPageManager.post({
            identifier,
        });
        setLoadingIdentifier(null);

        if (success) {
            window.location.reload();
            return;
        }

        if (status === 422) {
            message.error((response as any).response.data.message);
        }
    };

    const staticPages = [
        {
            title: 'Menu',
            identifier: PAGE_IDENTIFIERS.MENU,
        },
        {
            title: 'Location & Hours',
            identifier: PAGE_IDENTIFIERS.LOCATION_HOURS,
        },
        {
            title: 'About Us',
            identifier: PAGE_IDENTIFIERS.ABOUT_US,
        },
        {
            title: 'Rewards',
            identifier: PAGE_IDENTIFIERS.REWARDS,
        },
        {
            title: 'Coupons',
            identifier: PAGE_IDENTIFIERS.COUPONS,
        },
    ];

    return (
        <div>
            {staticPages.map((page) => (
                <div key={page.identifier} className="mb-small">
                    <button
                        className="w-full p-small border border-brand-inkGrey-grey_2 border-solid rounded-md shadow-section bg-background-inkWhite-white_0 flex justify-between items-start"
                        onClick={() => onSave(page.identifier)}
                        type="button"
                    >
                        <div className="flex flex-row justify-between w-full">
                            <h6>{page.title}</h6>
                            {loadingIdentifier === page.identifier ? <Spin /> : <PlusOutlined />}
                        </div>
                    </button>
                </div>
            ))}
        </div>
    );
}

export default StaticPage;
