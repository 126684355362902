import { useEffect, useMemo, useRef, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { TimelineItemProps, Spin, Progress, Timeline, message } from 'antd';
import collect from 'collect.js';
import Lottie from 'react-lottie-player';
import useRdxStore from '../hooks/useRdxStore';
import { StoreSetupInterface } from '../exports/Interfaces';
import CreateStore from '../assets/animations/CreatingStore.json';
import Upload from '../assets/animations/Upload.json';
import StoreSetupManager from '../services/api/store/setup/StoreSetupManager';
import InitialDataManager from '../services/api/InitialDataManager';
import { SETUP_STEPS } from '../exports/Enums';

interface Props {
    children: JSX.Element;
}

function SetupFailed(): JSX.Element {
    const { store } = useRdxStore();

    return (
        <div className="fixed inset-0 flex flex-col items-center justify-center lg:p-large p-small">
            <h6 className="mb-small text-center">The creation of the website did not complete successfully. Error:</h6>
            <p className="mb-small text-center">{store.setup_error}.</p>
            <p className="text-center">
                Please delete the page and try again. If the problem persists, please contact our support. Thank you for
                your understanding!
            </p>
        </div>
    );
}

function SetupProgress(): JSX.Element {
    const [loading, setLoading] = useState<boolean>(true);
    const [setup, setSetup] = useState<StoreSetupInterface | null>(null);
    const setupRef = useRef(setup);
    setupRef.current = setup;

    const getStoreSetup = async (): Promise<void> => {
        const { response, success } = await StoreSetupManager.get();
        setLoading(false);

        if (!success) return;

        setSetup(response.data);
    };

    const stepIndex = useMemo(
        () => Math.ceil((setup?.setup_steps?.length || 0) * ((setup?.setup_progress || 0) / 100)),
        [setup],
    );

    const getStepTitle = (step: SETUP_STEPS): string => {
        switch (step) {
            case SETUP_STEPS.CREATE_HOME_PAGE:
                return 'Create Home page';
            case SETUP_STEPS.CREATE_ABOUT_US_PAGE:
                return 'Create About Us page';
            case SETUP_STEPS.CREATE_MENU_PAGE:
                return 'Create Menu page';
            case SETUP_STEPS.CREATE_LOCATION_PAGE:
                return 'Create Location page';
            case SETUP_STEPS.CREATE_COUPON_PAGE:
                return 'Create Coupon page';
            case SETUP_STEPS.CREATE_REWARDS_PAGE:
                return 'Create Rewards page';
            case SETUP_STEPS.TOGGLE_PLUGINS:
                return 'Activate Plugins';
            case SETUP_STEPS.FILL_GALLERY:
                return 'Filling Gallery';
            default:
                return 'Setup Store';
        }
    };

    function renderTimeLineItem(step: SETUP_STEPS, index: number): TimelineItemProps {
        let dot = null;
        if (index === stepIndex) {
            dot = <Spin size="small" />;
        } else if (index < stepIndex) {
            dot = <CheckOutlined />;
        }

        return {
            children: <h6>{getStepTitle(step)}</h6>,
            dot,
        };
    }

    useEffect(() => {
        if (setupRef.current?.setup_progress !== 100 && !setupRef.current?.setup_error) {
            const interval = setInterval(() => {
                getStoreSetup();
            }, 2000);

            return () => clearInterval(interval);
        }
        return undefined;
    }, []);

    useEffect(() => {
        if (setup?.setup_progress === 100) {
            setTimeout(InitialDataManager.get, 8500);
            return;
        }
        if (setup?.setup_error) {
            InitialDataManager.get();
        }
    }, [setup?.setup_progress, setup?.setup_error]);

    if (loading || !setup) {
        return (
            <div className="fixed w-screen h-screen flex justify-center items-center bg-white">
                <Spin size="large" />
            </div>
        );
    }

    if (setup?.setup_progress === 100) {
        return (
            <div className="fixed inset-0 flex flex-col justify-center items-center" style={{ zIndex: 1000 }}>
                <Lottie loop animationData={Upload} speed={0.5} play style={{ width: '30%', height: 'auto' }} />
                <p className="mt-small">Preparing Website...</p>
            </div>
        );
    }

    if (setup?.setup_error) {
        return <SetupFailed />;
    }

    return (
        <div
            className="fixed inset-0 bg-white grid lg:grid-cols-2 grid-cols-1 gap-4 lg:overflow-hidden overflow-scroll"
            style={{ zIndex: 1000 }}
        >
            <div className="lg:p-large p-small">
                <Progress type="line" percent={setup.setup_progress} className="mb-large" />
                <Timeline
                    items={collect([...setup.setup_steps, SETUP_STEPS.FILL_GALLERY])
                        .map(renderTimeLineItem)
                        .toArray()}
                />
            </div>
            <div className="flex justify-center items-center bg-companyBrand-primary/50">
                <Lottie loop animationData={CreateStore} play style={{ width: '100%', height: 'auto' }} />
            </div>
        </div>
    );
}

function StoreSetupProvider({ children }: Props): JSX.Element {
    const { store } = useRdxStore();
    const [messageApi, contextHolder] = message.useMessage();

    if (!store) {
        return (
            <div className="fixed w-screen h-screen flex justify-center items-center bg-white">
                <Spin size="large" />
            </div>
        );
    }

    useEffect(() => {
        if (!store) {
            return;
        }

        if (
            (store?.setup_step === SETUP_STEPS.FILL_GALLERY ||
                store?.setup_step === SETUP_STEPS.FILL_COMPANY_GALLERY) &&
            store?.setup_progress === 100
        ) {
            messageApi.open({
                type: 'loading',
                content: 'Filling gallery. This can take up to 5 minutes.',
                duration: 5,
            });
        }
    }, [store?.setup_step]);

    return (
        <>
            {contextHolder}
            {store?.setup_progress !== 100 ? <SetupProgress /> : children}
        </>
    );
}

export default StoreSetupProvider;
